<template>
    <div class=container v-if="bloaded && aloaded">
        <p class=title> Bibtex Entry for {{ bibtex[paper].cite }}</p>
        <br>
        <br>
        <div class=info>
            <!-- APA format -->
            <b>{{ apa[paper].title }}<br> </b>
            <span v-for="line in apa[paper].rest" :key="line">{{ line }}<br> </span>
            <br>
            <br>
            <!-- Bibtex format -->
            <span v-for="line in bibtex[paper].rest" :key="line">{{ line }}<br> </span>
        </div>
    </div>
</template>

<script>
import apa from "@/assets/publications/APA.json"
import bibtex from "@/assets/publications/Bibtex.json"

export default {
    data() {
        return {
            paper: this.paper,
            apa: apa,
            bibtex: bibtex,
            aloaded: true,
            bloaded: true
        }
    },

    created() {
        this.paper = this.$route.query.bid;
    }
}
</script>

<style scoped>
.container {
    margin: auto;
    width: 60%;
}

.title {
    font-weight: bold;
    /* font-family:'Raleway', sans-serif; */
}

.info {
    text-align: left;
}
</style>